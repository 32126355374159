/**
 * Style tabel 
 * Kolorystyka: CTS24
**/
table {
  width: 100%;
}
table.list ._select {
  width: 75px !important;
}

table.noborder,
table.noborders,
table.borderless,
table.noborder td,
table.noborders td,
table.borderless td,
table.noborder th,
table.noborders th,
table.borderless th {
  border: 0px !important;
}

table.details,
table.list {
  margin: 10px 0px;

  width: 100%;

  font-size: 14px;

  border-spacing: 0px;
  border-collapse: separate;

  border-top: 1px solid #d6d8db;
  border-left: 1px solid #d6d8db;

  border-radius: 5px;
  box-shadow: 0 0 5px 0.25px #cacaca;

  background-color: #ffffff;
}

table.details td,
table.list th,
table.list td {
  padding: 10px;

  // border-right: 1px solid #d6d8db;
  border-bottom: 1px solid #d6d8db;
}

table.details.simple {
  /** adjusted */
  background-color: transparent;
  box-shadow: none;
  border: none;

  td {
    /** adjusted */
    border: none;
    background: none !important;
  }
}

table.list {
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center{
    text-align: center;
  }
}


table.list tr th {
  font-size: 14px;
  font-weight: 500;
}

table tr:first-child th {
  background-color: #f9f9f9;
}

table tr:first-child th:first-child {
  border-radius: 7px 0 0 0;
}

table tr:first-child th:last-child {
  border-radius: 0 7px 0 0;
}

table.details > tbody > tr:last-child > td:first-child {
  border-radius: 0 0 0 7px;
}

table.details > tbody > tr:last-child > td:last-child {
  border-radius: 0 0 7px 0;
}

table.details .value,
table.details .value table td {
  input {
    width: 100%;
  }
  &.cbx-inline {
    .checkbox {
      display: inline-block;
    }
  }
}

table.details .required {
  width: 10px;

  text-align: center;
  color: #69b645;
}

table.details .information,
table.details .error {
  font-size: 11px;
}

table.details .information {
  color: #474747;
}


table.details td.caption {
  background: #f9f9f9;
  width: 100px;
  &.mandatory {
    &:after {
      content: "*";
      position: relative;
      left: 2px;
      color: #69b645;
      font-size: 18px;
      line-height: 0;
      height: 5px;
    }
  }
}

table.details > tbody > tr:first-child > td.caption:first-child {
  border-radius: 7px 0 0 0;
}

table.details > tbody > tr:last-child > td.caption:first-child {
  border-radius: 0 0 0 7px;
}

table.details > tbody > tr:first-child:last-child > td.caption:first-child {
  border-radius: 7px 0 0 7px;
}

table.details td.group {
  padding: 10px 0px;
  font-weight: bold;
  font-size: 15px;
}

table.list tr.filters th {
  text-align: center;
  background: #f9f9f9;
}

table.list tr.filters th input.text,
table.list tr.filters th select {
  width: 100%;

  margin-left: -4px;
  padding: 5px 2px;

  border: 1px solid #abadb3;
}

table.list tr.filters th.buttons input {
  background-color: #ffcaca;
}

table.list td.actions {
  text-align: center;
}

//* waste list */
.waste-list {
  .bold {
    font-size: 15px;
    color: $dark2;
  }

  .link {
    color: #65b1d9;
    cursor: pointer;
  }

  .status {
    display: flex;
    width: 160px;
    white-space: nowrap;
    img {
      margin-right: 5px;
    }
  }

  .actions {
    text-align: right;
    white-space: nowrap;
    svg {
      cursor: pointer;
    }
  }

  .edit {
    color: $primary-green;
  }

  .desc {
    width: 40%;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}

.row-summary{
  td{
    font-weight: 500;
  }  
}

.header-row{
  background-color: #f9f9f9;
  td{
    font-weight: 500;
  }  
}

table.no-box-shadow{
  box-shadow: none !important;
  border-right: 1px solid #d6d8db;
}

