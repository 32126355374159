$red-color : red;
$red2 : darken( red, 7%);
$primary-green: #7cb143;
$dark : #55544D;
$dark2: #3B3E45;
$dark3 : #838A93;
$black : #000000DE;
$grey: lighten( #55544D, 30%);
$grey2 : #0000008A;
$light-grey: darken( #efefef, 12%);
$light-grey2: #efefef ;
$blue : #4A90E2;
$blue2 : #65B1D9;