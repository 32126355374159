@import 'src/styles/base/variables.scss';
.working {
  width: 338px;
  height: 320px;

  background: #ffffff url("/public/images/working.jpg") no-repeat center center;

  text-align: center;
  font-size: 15px;
  border: 1px solid #cacaca;
  border-radius: 10px;
}

/* Okno komunikatów sc.js */

.w-header {
  background-color: #7cb143 !important;
}

.cool-hint {
  position: absolute !important;
  padding: 5px 7px !important;

  max-width: 150px !important;

  color: #ffffff !important;

  font-size: 12px !important;
  line-height: 13px !important;
  font-weight: normal !important;
  text-align: center !important;
  white-space: normal !important;

  border-radius: 5px !important;
  -moz-border-radius: 5px !important;

  background: rgba(0, 0, 0, 0.9) !important;
}
/*** Style ogólne *************************************************************/

body {
  overflow-y: scroll;
  background-color: #eaeced;
}

#page-panel * {
}

.error {
  color: red;
}

a,
.link {
  cursor: pointer;
  color: $blue;
  text-decoration: none;

  &.disabled{
    color : lighten($color: $blue, $amount: 25%) !important;
    
  }
}

button.link{
  border: none;
  background-color: none;
  background: none;
  font-size: inherit;
}

a:hover,
.link:hover {
  text-decoration: underline;
}

a img {
  border: 0px;
}

body,

table,
input,
textarea,
select {
  font-family: "Roboto", "Open Sans", "Tahoma", "Verdana", sans-serif;
  font-size: 14px;
  color: $dark3;
}

#CM {
  position: absolute;

  margin: 10px 0px 0px -210px;
  width: 204px;

  background: white;

  z-index: 9999999;
}

table.no-borders,
table.no-borders th,
table.no-borders td {
  border: 0px !important;
}

label,
input[type="checkbox"] {
  cursor: pointer;
}

input[type="checkbox"] {
  border: 1px solid #69b645;
}

*:focus {
  outline: none;
}

th._select div {
  cursor: pointer;
  margin: -17px 0px 0px 1px;
}

._select .loader {
  height: 19px;
  width: 16px;

  margin-left: 2px;

  float: left;
  display: none;

  background: url("/public/images/loader-mini.png") no-repeat;
}

._select {
  width: 21px;
}

/* PW */

.data-pw #pw-frame {
  width: 600px !important;
}

.data-pw .content {
  width: 580px !important;
}

.data-pw .content table tr:hover td {
  background: #d3fc9f;
}

/* Uniwersalna wyszukiwarka */

.search-box {
  margin: 10px auto;
  padding: 5px 5px 7px 5px;

  border: 1px solid #d0d4d9;

  background: #f1f3f6;
}

.search-box-caption {
  float: left;

  width: 100px;

  padding: 6px 5px 4px 5px;

  text-align: center;
}

.search-box-field {
  margin-left: 100px;
  background: red;
}

.search-box-clear,
.search-box-submit {
  float: right;
}

.search-box-submit div {
  position: relative;
  z-index: 1;

  margin: 3px -3px 0px 5px;
  padding: 2px 4px;

  width: 60px;
  text-align: center;

  cursor: pointer;

  border: 1px solid #cacaca;
  border-radius: 4px;

  background: #efefef;
}

.search-box-submit div:hover {
  background-color: #d3fc9f;
  border: 1px solid #8edb59;
}

.search-box-clear div {
  position: relative;
  z-index: 1;

  margin: 3px 2px 0px 5px;
  padding: 2px 4px;

  width: 9px;

  font-weight: bold;

  cursor: pointer;

  border: 1px solid #cacaca;
  border-radius: 4px;

  background: #efefef;
}

.search-box-clear div:hover {
  background-color: #f99f9f;
  border: 1px solid #d85858;
}

.search-box input {
  position: absolute;

  margin: 0px auto;
  padding: 5px 100px 5px 5px;

  width: 493px;
  z-index: 0;

  border: 1px solid #cacaca;
  border-radius: 5px;
}

/*** Strona *******************************************************************/

#page-panel {
  // min-width: 1370px;
  min-width: 1100px;
}

#page-body {
}

/*** Nagłówek *****************************************************************/

#header-panel {
  /*
	position: fixed;
	width: 100%;
	*/
  min-width: 1000px;

  border-bottom: 1px solid #e9e9ea;

  background-color: #3a3f45;
  background-position: left top;
  background-repeat: no-repeat;
}

#h-body {
}

#header-panel .header-banner {
  margin-top: 5px;
  width: 950px;
  height: 208px;
}

#header-panel .header-banner-text {
  padding: 130px 0px 0px 40px;

  width: 350px;

  color: #ffffff;
}

.userpanel {
  display: none; /* tymczasowo */

  margin-top: 45px;

  border: 1px solid #606d83;
  border-radius: 3px 0px 0px 3px;

  background: #273145;
}

.userpanel .avatar {
  margin-right: 2px;

  border: 1px solid #606d83;

  background: #273145;
}

.userpanel .full-name {
  color: #ffffff;
}

#sites-panel {
  position: absolute;
  top: 70px;
  right: 0;

  height: 25px;

  background: none;
}

#sites-panel .current-site {
  padding: 5px 10px 5px 10px;

  text-decoration: underline;

  background: none;
}

#sites-panel a {
  padding: 5px 10px 5px 10px;
  color: #616468;

  background: none;
}

#sites-panel a:hover {
  color: #69b645;
}

#sites-panel-icon {
  display: none;
}

/*** Panel główny *************************************************************/

#main-panel {
  padding-bottom: 140px;
}

#main-panel #m-header {
}

#main-panel #m-footer {
}

/*** Lewy panel ***************************************************************/

#left-panel {
}

/*** Środkowy panel ***********************************************************/

#middle-panel {
  margin-left: -1px;

  width: 1160px;
  padding: 25px 20px;

  /*	background-color: #ffffff; */

  border-radius: 5px;
}

#middle-panel .body {
  /*
	padding-top: 140px !important;
	padding-left: 170px !important;
	*/
}

#middle-panel h1 {
  margin: 0 0 5px 0;
  padding: 0;

  font-size: 28px;
  font-weight: normal;
  color: #69b645;
}

#middle-panel h3 {
  margin: 0 0 15px 0;
  padding: 0;

  font-size: 14px;
  font-weight: normal;
  color: #79818c;
}

#middle-panel h2 {
  margin: 0 0 15px 0;
  padding: 0;

  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto-Regular";
  color: #3a3f45;
}

#middle-panel h2 a:hover {
  text-decoration: none;
  cursor: default;
}

#middle-panel h2 {
  // border-bottom: 1px solid #eaeced;
}

.cm-title-edit {
  margin: 0px 0px 10px -1px !important;
  padding: 1px 0px 0px 0px !important;

  width: 100%;

  border: 0px;
  border-bottom: 2px ridge #dd0000;
}

.main-actions a {
  color: #060e25;
  font-weight: bold;
}

/*** Prawy panel **************************************************************/

#right-panel {
}

#right-panel h3 {
  padding: 0px;
  margin: 0px;

  color: #69b645;
}

#right-panel hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(105, 182, 69, 1),
    rgba(0, 0, 0, 0) 60%
  );
}

#right-panel .body {
}

/*** Stopka *******************************************************************/

#footer-panel {
  margin-bottom: 20px;
}

#f-body {
}

/*** Elementy *****************************************************************/

/* Ogólne style */

#left-panel {
}

#left-panel .caption {
  height: 35px;
  padding: 10px 10px 0px 10px;
  margin-bottom: -10px;

  font-weight: bold;
  color: #69b645;

  background: url("/public/images/element-panel-caption.png") no-repeat center
    top;

  border-radius: 3px;
}

/* Treść */

#middle-panel .element-textbox .caption {
  margin: 0px;
  padding: 14px 10px 8px 0px;

  font-weight: bold;
  color: #69b645;
  font-size: 16px;

  background: url("/public/images/h1.png") repeat-x left bottom;
}

/* Menu */
/*
#left-panel ul
{
	list-style-type: none;
	padding: 0px;
	margin: 0px;
}

#left-panel li.subitems
{
	background: none !important;
}

#left-panel .menu-level-1 li
{
	min-height: 27px;

	text-transform: uppercase;

	background: url('/public/images/menu-level-1-item.png') no-repeat left top;
}

#left-panel .menu-level-1 li.current,
#left-panel .menu-level-1 li.current-parent,
#left-panel .menu-level-1 li:hover
{
	background: url('/public/images/menu-level-1-item-selected.png') no-repeat left top;
}

#left-panel .menu-level-1 li a
{
	display: block;
	padding: 7px 0px 0px 25px;

	text-decoration: none;
	font-weight: bold;

	color: #ffffff;
}

#left-panel .menu-level-1 li a:hover,
#left-panel .menu-level-1 li.current a,
#left-panel .menu-level-1 li.current-parent a
{
	color: #ffffff;
}

#left-panel .menu-level-2
{
	list-style-type: none;
	padding: 0px;
	margin: 0px 0px 0px 0px;
}

#left-panel .menu-level-2 li
{
	text-transform: none;

	background: none;
}

#left-panel .menu-level-2 li.current
{
	background: none;
}

#left-panel .menu-level-2 li:hover
{
	background: none;
}

#left-panel .menu-level-2 li a
{
	display: block;

	padding-left: 30px;

	font-weight: bold;
	color: #060e25;
}

#left-panel .menu-level-2 li a
{
	background: none;
	background: url('/public/images/menu-level-2-item.png') no-repeat 15px 12px;
}

#left-panel .menu-level-2 li.current a,
#left-panel .menu-level-2 li.current-parent,
#left-panel .menu-level-2 li a:hover
{
	color: #69b645;
	border-bottom: 0px;

	background: url('/public/images/menu-level-2-item-selected.png') no-repeat 15px 12px;
}

#left-panel .menu-level-2 ul
{
	margin: 0px 0px 0px 10px;
}

#left-panel .menu-level-3 li
{
	padding: 3px 0px;
	background: none;
}

#left-panel .menu-level-3 li a
{
	padding-left: 10px;
	background: url('/public/images/list-item.png') no-repeat left 5px;
}

#left-panel .menu-level-3 ul
{
	margin: 0px;
	padding: 0px 0px 0px 3px;
}

#left-panel .menu-level-3 li li
{
	margin: 0px;
	padding: 0px;
}

#left-panel .menu-level-3 li li a
{
	font-size: 9px;
	background: none;
}

*/

/* Logo/tytuł portalu */

.logo {
  position: absolute;
}

.logo img {
  margin: -2px 10px 10px 0px;
  width: 175px;
}

/* Odstępy */

/* Panel użytkownika */

#header-panel .element-userpanel {
  margin-bottom: 10px;

  width: 337px !important;
  height: 42px !important;

  background: #f2f3f6 !important;
  border: 1px solid #d0d4d9;

  border-radius: 5px;
}

#header-panel .element-userpanel .caption {
  float: left;

  margin-top: 10px;
  width: 90px;

  text-align: center;
}

#header-panel .element-userpanel .username-caption,
#header-panel .element-userpanel .password-caption {
  display: none;
}

#header-panel .element-userpanel input.username,
#header-panel .element-userpanel input.password {
  margin-top: 5px;
  padding: 6px 5px 7px 5px;

  width: 102px;

  border: 1px solid #d0d4d9;

  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;
}

#header-panel .element-userpanel .login-button {
  width: 9px !important;
  height: 19px !important;

  border: 0px;

  position: absolute;
  margin: 10px 0px 0px -19px;

  background: url("/public/images/login-button.png") no-repeat center center !important;
}

#header-panel .element-userpanel .logged-user .caption {
  display: none;
}

#header-panel .element-userpanel .logged-user .info {
  width: 300px;
  text-align: center;
}

#header-panel .element-userpanel .logged-user .full-name {
  margin-top: 2px;
}

#header-panel .element-userpanel .avatar {
  padding: 3px;
  margin: -13px 0px 0px 0px;

  border: 1px solid #d0d4d9;

  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  border-radius: 4px;

  background-color: #f2f3f6;
  background-repeat: no-repeat;
  background-position: center center;
}

/* Aktualności */

.element-mininews {
  margin-top: 13px;
}

#left-panel .element-mininews {
  background: #f1f3f6;
}

#middle-panel .element-mininews .caption {
  margin: 0px;
  padding: 1px 10px 8px 0px;

  font-weight: bold;
  font-size: 16px;
  color: #69b645;

  background: url("/public/images/h1.png") repeat-x left bottom;
}

.element-mininews .news-item a,
.element-mininews .more a {
  padding-left: 7px;
  background: url("/public/images/list-item.png") no-repeat left 5px;
  text-decoration: none !important;
}

.element-mininews .news-item {
  margin: 10px 5px 10px 0px;
  padding-bottom: 10px;
  background: url("/public/images/dotted-separator.png") repeat-x left bottom;
}

#left-panel .element-mininews .news-item {
  padding: 10px;
}

.element-mininews .news-item.news-last {
  background: none;
}

.element-mininews .news-caption {
  margin-bottom: 3px;

  font-weight: bold;
  text-align: justify;
}

.element-mininews .news-content {
  text-align: justify;
}

.element-mininews .news-date {
  display: block;
  margin-top: 5px;

  color: #6a6f73;
}

.element-mininews .news-date-caption {
  color: #a1a7ad;
}

.element-mininews .news-more {
  float: right;
  margin-top: -15px;
}

.element-mininews .news-image img {
  float: left;

  padding: 1px;
  margin: 3px 10px 3px 0px;

  border: 1px solid #aaaaaa;
}

.element-mininews .more {
  padding: 5px 10px 10px 10px;
  text-align: right;
}

/* Bannery */

.element-bannerzone {
  margin: 0px !important;
  padding: 0px !important;
  border: 0px !important;
}

.element-bannerzone .banner-zone {
  margin: 10px auto;
}

.element-bannerzone .element-content-manage {
  position: absolute;
  padding: 3px 0px;

  color: #ffffff;

  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.75);
}

.element-bannerzone .banner-zone-text {
  display: table-cell;

  font-weight: bold;
  color: #ffffff;

  text-align: center;
  vertical-align: bottom;
}

/* Narzędzia */

.element-toolbox {
  position: absolute;

  top: 10px;
  left: 10px;

  width: 100px !important;

  border: 0px !important;
}

.element-toolbox .element-body {
  padding: 25px 10px !important;
  margin: 0px !important;
}

/* Kontakt */

.element-miniinfo {
  background-color: #ffeeee;
}

.element-miniinfo .contact-body {
  padding: 10px;
}

.element-miniinfo .contact-body ul {
  margin: 0px;
  padding: 0px;

  list-style-type: none;
}

.element-miniinfo .contact-body a {
  padding-left: 10px;

  background: url("/public/images/list-item.png") no-repeat left 5px;
}

/*** Pozostałe ****************************************************************/

/* Linki serwisów */

.sites-links {
  float: left;

  height: 35px;
  width: 900px;
}

.sites-links ul {
  margin: -4px 0px 0px 0px;
  padding: 0px;

  height: 35px;

  list-style-type: none;
}

.sites-links ul li {
  float: left;
  padding: 13px 0px 0px 1px;
  height: 21px;
  text-align: center;

  font-weight: bold;
  font-size: 12px;
  color: #69b645;
}

.sites-links ul li.current {
  text-align: center;
  padding: 12px 0px 0px 0px;
  color: #d90000;
  height: 22px;
  background: #ffffff;

  border: 1px solid #d0d4d9;
  border-bottom: 0px;

  -moz-border-radius: 3px 3px 0px 0px;
  -webkit-border-radius: 3px 3px 0px 0px;
  -khtml-border-radius: 3px 3px 0px 0px;
  border-radius: 3px 3px 0px 0px;
}

.sites-links ul li.main {
  width: 65px;
}

.sites-links ul li.translation {
  width: 105px;
}

.sites-links ul li.insurance {
  width: 115px;
}

.sites-links ul li.assistance {
  width: 95px;
}

.sites-links ul li.credit {
  width: 85px;
}

.sites-links ul li.translation-system {
  width: 155px;
}

.sites-links ul li.credit-system {
  width: 150px;
}

.sites-links ul li.separator,
.sites-links ul li.separator:hover {
  margin: 2px 1px 0px 2px;
  padding: 0px;

  border: 0px;
}

.sites-links ul li a {
  color: #69b645;
  text-decoration: none;
}

/* Kroki */

.steps {
  margin: 10px 0px;
}

.steps ul {
  margin: 0px;
  padding: 0px;

  list-style-type: none;
}

.steps li {
  margin: 0px;
  padding: 0px;

  float: left;

  text-align: center;

  background: #d5d5d5;
}

.steps .first {
  -moz-border-radius: 10px 0px 0px 10px;
  -webkit-border-radius: 10px 0px 0px 10px;
  -khtml-border-radius: 10px 0px 0px 10px;
  border-radius: 10px 0px 0px 10px;
}

.steps .last {
  -moz-border-radius: 0px 10px 10px 0px;
  -webkit-border-radius: 0px 10px 10px 0px;
  -khtml-border-radius: 0px 10px 10px 0px;
  border-radius: 0px 10px 10px 0px;
}

.steps li.inactive {
  padding: 5px 0px;
  margin: 0px;

  background: #efefef;
  color: #999999;
}

.steps li a {
  margin: 0px;
  padding: 0px;

  display: block;
  padding: 5px;

  text-decoration: none;
  color: #565656;

  background: none;
}

/* Predefiniowane filtry */

.predefined-filter {
  margin: 5px 0px;
  padding: 5px 3px;

  border: 1px solid #dadada;
  border-radius: 5px;
  box-shadow: 0 0 3px 0.5px #dadada;

  background: #ffffff;
}

.predefined-filter ul {
  margin: 0px;
  padding: 0px;
}

.predefined-filter li {
  display: inline;

  padding: 0px;
  margin: 0px 0px 0px -1px;

  background: none;

  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -khtml-border-radius: 0px;
  border-radius: 0px;
}

.predefined-filter li.title {
  padding: 0px;
  margin: 0px;

  font-weight: bold;
  color: #454545;
}

.predefined-filter li.title span {
  display: block;
  float: left;

  padding: 5px;
  margin: -5px 2px 0px -3px;
}

.predefined-filter li.title:hover {
  color: #454545;
}

.predefined-filter li.current {
  padding: 4px 6px;
  margin: 0px -1px 0px -1px;

  color: #69b645;
  background-color: #f2f3f7;
}

.predefined-filter li a {
  margin: 0px;
  padding: 4px 6px;

  text-decoration: none;
  color: #454545;

  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  -khtml-border-radius: 0px;
  border-radius: 0px;
}

.predefined-filter li a:hover {
  color: #454545;
  background-color: #efefef;
}

/* W formie listy */

.predefined-filter table {
  margin: -5px 0px -5px -3px;
}

.predefined-filter table td {
  padding: 0px;
  margin: 0px;
}

.predefined-filter table td.title div {
  margin: 5px 0px 5px 5px;
}

.predefined-filter table .title {
  padding: 0px;
  margin: 0px;

  font-weight: bold;
  color: #454545;
  background-color: #efefef;
}

.predefined-filter table .predefined-filter-current-value {
  margin-left: 6px;
}

.predefined-filter table .predefined-filter-list {
  margin-top: -6px;
  background: white;
  border: 1px solid #cacaca;
  width: 597px;
}

.predefined-filter table .predefined-filter-list a,
.predefined-filter table .predefined-filter-list div.current {
  display: block;
  padding: 5px;
  text-decoration: none;
}

.predefined-filter table .predefined-filter-list a:hover,
.predefined-filter table .predefined-filter-list div.current:hover {
  background: #69b645;
}

.predefined-filter table .show-list {
  float: right;
}

/* Menu poziome */

.element-barmenu.manage-menu {
  margin: 0px auto;
  width: 950px;
  height: 41px;
}

.element-barmenu.manage-menu .structure-options {
  margin: 22px 0px 0px 0px;
}

.element-barmenu.manage-menu ul {
  margin: 0px 0px 0px 0px;
  padding: 0px;

  height: 35px;
  float: left;

  overflow: hidden;
}

.element-barmenu.manage-menu ul li {
  margin: 10px 0px 10px 0px;
  padding: 0px 5px;

  float: left;
  list-style-type: none;

  background: url("/public/images/important-links-separator.png") no-repeat
    right center;
}

.element-barmenu.manage-menu ul li.first {
  padding-left: 0px;
}

.element-barmenu.manage-menu ul li.last {
  background: none;
}

.element-barmenu.manage-menu ul li a {
  font-weight: bold;
  padding: 8px 10px;
  font-size: 11px;
}

.element-barmenu.manage-menu ul li a:hover {
  border: 1px solid #d90000;
  padding: 7px 9px;
  background: #d90000;

  font-weight: bold;
  text-decoration: none;
  color: #ffffff;

  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  border-radius: 3px;
}

/* Menu poziome nagłówkowe */

.element-barmenu.header-menu {
  margin: 0px;
  width: 337px;
  height: 19px;
  overflow: auto;
}

.element-barmenu.header-menu .structure-options {
  margin: 15px 0px 0px 0px;
}

.element-barmenu.header-menu ul {
  margin: 0px;
  padding: 0px;

  height: 19px;
  float: right;

  overflow: hidden;
}

.element-barmenu.header-menu ul li {
  margin: 0px;
  padding: 0px 0px;

  float: left;
  list-style-type: none;

  background: url("/public/images/quick-links-list-item.png") no-repeat left
    center;
}

.element-barmenu.header-menu ul li.first {
  padding-left: 0px;
}

.element-barmenu.header-menu ul li.last {
}

.element-barmenu.header-menu ul li a {
  color: #6a6f73;
  padding: 8px 10px;
  font-size: 11px;
}

.element-barmenu.header-menu ul li.last a {
  padding-right: 0px;
}

.element-barmenu.header-menu ul li a:hover {
  text-decoration: none;

  color: #dc1313;
}

/* Powiadomienia systemowe */

.system-notice {
  margin: 0px 0px 5px 0px;
  padding: 10px;

  border: 2px solid #cacaca;
  -moz-border-radius: 4px; /* Firefox */
  -webkit-border-radius: 4px; /* Safari, Chrome */
  border-radius: 4px; /* CSS3 */

  background: #f3f3f3;
}

.system-notice-close {
  color: #cacaca;
}

.system-notice-success {
  border: 2px solid #77cc77;
}

.system-notice-error {
  border: 2px solid #db3131;
}

.system-notice-info {
  border: 2px solid #70acda;
}

/* Boks z dowolną treścią */

.element-textbox ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.element-textbox li {
  padding: 5px 0px;
  background: url("/public/images/dotted-separator.png") repeat-x left bottom;
}

.element-textbox li a {
  padding-left: 10px;
  background: url("/public/images/list-item.png") no-repeat 3px center;
}

/* Stopka wykonawcy */

#footer-panel {
  position: fixed;

  margin: 10px 0px 0px 0px;
  bottom: 0px;

  width: 100%;
  height: 60px;

  background: #3a3f45;
}

#f-body {
  padding: 3px 10px;
  display: flex;
  align-items : center;

}

#company-info {
  margin-top: 5px;
  float: left;
  flex-grow: 1;
  width: 50%;
  color : #79818c;
}

#author-info {
  float: right;

  margin: 2px 0px 0px 16px;
  padding: 0px 2px 0px 2px;

  width: 188px;
  // height: 20px;

  color: #909090;
  font-size: 9px;

  border-radius: 5px;
}

#author-info div.scope {
  float: left;

  padding: 3px 7px 3px 0px;

  width: 98px;

  font-size: 10px;
  text-align: right;

  border-right: 1px dotted #bbbbbb;
}

#author-info div.scope a {
  color: #909090 !important;
  text-decoration: none;
}

#author-info:hover div.scope a {
  color: #266c8f;
}

#author-info img {
  margin: 2px 0px 0px 5px;
}

// **** new styles added in react front **** //

// /*** Style ogólne *************************************************************/

.cleaner-box {
  clear: both;
}

body {
  margin: 0px;
  padding: 0px;
}

body {
  font-size: 13px;
  // font-family: "Roboto-Regular", "Tahoma", "Verdana", "sans-serif";
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8,
h9 {
  margin: 0px;
}

.price {
  font-size: 18px;
  font-family : 'Roboto-Regular';
  
  .light {
    text-align: right;
    font-size: 16px;
  }
  margin-top: 30px;
  > label {
    font-weight: bold;
    color: black;
  }
  p {
   
    margin:0;
  }
  .value {
    color: #7cb143;
    font-size: 28px;
    font-weight: 400;
    font-family : 'Roboto-Medium';
    &.old {
      color: grey;
      text-decoration: line-through;
    }
  }
}

.em {
  font-weight: bold;
  color: black;
}
