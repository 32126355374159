@import 'src/styles/base/variables.scss';
@import 'src/styles/modules/modal.module.scss';

.modal{
    width: 800px;   
}

.address_container{
    display: flex;

    align-items: center;
    margin-top:10px;
    margin-bottom: 20px;
    color: #565655;
    background: #fffdf3;
    padding: 10px 20px;
    border-bottom: 2px solid #cacaca;
}
.header-content{
    display:inline-block;
    width:100%;
}
button.add_btn{
    margin-left: 15px;
    height: 26px;
    float:right;
    span{
        vertical-align: top;
        line-height: 26px;
        position: relative;
        top: 1px;
    }
       
}

.address{
    flex-grow: 1;
    font-weight: 700;
    font-family: Calibri;
    line-height: 6px;
}

.link_container{
    vertical-align: middle;
    a{
        font-weight: bold;
    }
}

