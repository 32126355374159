.email-modal{
    input{
        width: 100%;
        margin-bottom: 10px;
    }
 
}

.address-header{
    font-weight: 500;
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: -5px;
}

.report-email{
    :global(.caption){
        width: 75px !important;
    }
}