/*** Style ogólne *************************************************************/

.cleaner-box
{
	clear: both !important;
}

body
{
	margin: 0px;
	padding: 0px;
}

body, table
{
	font-family: "Calibri", "Open Sans", "Tahoma", "Verdana", "sans-serif";
}

h1, h2, h3, h4, h5, h6, h7, h8, h9
{
	margin: 0px;
}

/* Cookies */

#cookie-info-panel
{
	background: #ffffff !important;
}

.cookie-info-icon
{
	display: none !important;
}

.cookie-info-close
{
	font-size: 10px !important;
}

/*** Style grida *************************************************************/
.row{
	display: flex;
	margin: 0 -10px;
	.col-half{
		width:50%;
		padding: 0 10px;
		&.no-padding{
			padding:0;
		}
	}
	.col-8{
		width: 33.33%;
		padding: 0 10px;
	}
}



/*** Strona *******************************************************************/

#page-panel
{
	margin: 0px auto;
	padding: 0px;
}

#page-body
{
}

/*** Nagłówek *****************************************************************/

#header-panel
{
	height: 70px;
	max-height: 70px;
}

#h-header
{
	height: 14px;
}

#h-body
{
	margin: 0px auto;

	width: 1200px;
}

#hb-body
{
	margin: 10px 15px 6px 15px;
}

.cleaner-box
{
	clear: both !important;
}

/*** Panel główny *************************************************************/

#main-panel
{
	margin: 0px auto;

	width: 1200px;
}

/*** Lewy panel ***************************************************************/

#left-panel
{
	display: none;

	float: left;
	margin: 0px;

	min-height: 1px;
	width: 163px;
	
	border-right: 1px solid #cacaca;
}

#left-panel .body
{
	min-height: 10px;
}

/*** Środkowy panel ***********************************************************/

#middle-panel
{
	// float: left;

	min-height: 1px;
}

#middle-panel .body
{
	margin: 5px;
	padding: 5px;
}

/*** Prawy panel **************************************************************/

#right-panel
{
	display: none;

	float: left;
	
	min-height: 1px;
	width: 234px;
}

#right-panel .body
{
	margin: 5px 5px 5px 15px;
	min-height: 200px;
}

/*** Stopka *******************************************************************/

#footer-panel
{
}

#f-body
{
}


/*** Nowe style *******************************************************************/

.row{
	
	display:flex;
}
.half-col{
	width:50%;
}