@import 'src/styles/base/variables.scss';
// @import 'src/styles/base/mixins.scss';
//  @import 'src/styles/main.scss';

.menu_panel {
  height: 70px;
  margin-top: -14px;
  float: right;
  > div {
    display: inline-block;
    margin-right: 5px;
  }
}

.user_img {
  margin-bottom: -6px;
  position: relative;
}

.user_submenu {
  z-index: 1;
  margin-top: -10px;
  display: block;
  border: 1px solid rgb(239, 239, 239);
  box-shadow: rgb(0, 0, 0) 0px 0px 30px -5px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  position: absolute;
  width: 230px;
  margin-left: -183px;

  .arrow_top {
    float: right;
    margin-top: -14px;
    margin-right: 20px;
    border-top: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
    border-left: 7px solid transparent;
    width: 0px;
    height: 0px;
  }
}

.new{
  color: $primary-green;
  border: 1px solid $primary-green;
  border-radius: 20px;
  position: absolute;
  left: 10px;
  font-size: 8px;
  padding: 2px 6px 1px 6px;
  font-family: 'Roboto-Bold';
}

.docs{
  width: 230px;
  margin-left: -150px;
  
  top: 70px;
}