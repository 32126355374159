@font-face { font-family: 'Calibri'; src: url('/fonts/calibri.ttf');  }
@font-face { font-family: 'Roboto-Regular'; src: url('/fonts/Roboto-Regular.ttf');  }
@font-face { font-family: 'Roboto-Bold'; src: url('/fonts/Roboto-Bold.ttf');  }
@font-face { font-family: 'Roboto-Medium'; src: url('/fonts/Roboto-Medium.ttf');  }
@font-face { font-family: 'Roboto-Light'; src: url('/fonts/Roboto-Light.ttf');  }
@font-face { font-family: 'Calibri-Light'; src: url('/fonts/calibril.ttf');  }
@font-face { font-family: 'Calibri Black'; src: url('/fonts/calibrib.ttf');  }
@font-face { font-family: 'Oxygen-Regular'; src: url('/fonts/Oxygen-Regular.ttf');  }
@font-face { font-family: 'PT_Sans-Web-Regular'; src: url('/fonts/PT_Sans-Web-Regular.ttf');  }

// @font-face {
//     font-family: 'Poppins';
//     font-style: normal;
//     font-weight: 600;
//     font-display: swap;
//     src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url("/fonts/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2") format('woff2');
//     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
//   }
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url("/fonts/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }