@import 'src/styles/base/variables.scss';

.tablist{
    list-style: none;
    padding-left: 0;
    border-bottom: 1px solid $light-grey;
    margin-bottom: 15px;
}
.tab{
    font-family: 'Roboto-Regular';
    font-size: 18px;
    color: $dark;
    cursor: pointer;
    border-radius: 6px 6px 0px 0px;
    width: 169px;
    height:46px;
    display:inline-block;
    text-align:center;
    line-height: 46px;
    // &:global(.react-tabs__tab--selected){
    //     background-color:    white;

    // }
}

.tabs_right{
    float:right;
    &.hidden{
        display: none;
    }
    .tab{
        width: 80px;
    }
}
.selected{
    background-color:    white;
}