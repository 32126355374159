@import 'src/styles/base/variables.scss';
.not-found{
    text-align: center;
    position: absolute;
    top:0; 
    bottom:0;
    left:0;
    right:0;
    margin:auto;
    height: 200px;
}

.error{
    
    font-size: 60px;
    color : $dark;
    font-family: Roboto-Medium;
}

.info{
    font-size: 20px;
    margin-bottom: 40px;
}

.buttons{
    text-align: center;
}