@import 'src/styles/base/variables.scss';

.success_container{
    width:100%;
    height: calc(80vh - 100px);
    position: relative;
    overflow: hidden;
}

.success_box{
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    width: 400px;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
    h1{
    color: $dark !important;
    }
}

svg.success_icon{
    color: $primary-green;
    font-size: 60px;
}