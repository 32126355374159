@import 'src/styles/base/variables.scss';
@import 'src/styles/base/mixins.scss';

// :global(#p-body){
//   background-color: white;
//   min-height: 100vh;
// }
.info{
  margin-bottom: 30px;
}

.tcl {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
}

p{
  font-family: 'Roboto-Regular';
}

:global(.price) {
  display: inline-block;
  :global(.value){
    margin-right: 5px;
  }
  :global(.value.old) {
    margin-right: 10px;
    margin-left: 5px;
  }

}

.tcl > div:nth-child(1) {
  padding-right: 30px;
  border-right: 1px solid #efefef;
}



ol.flow_list {
  padding: 0;
  margin: 0 auto;
  list-style-type: none;
  counter-reset: steps;
  font-family: "Roboto-Regular";
}

ol.flow_list li {
  padding: 3px 0 20px 35px;
  position: relative;
  margin: 0;
  color: $grey2;
  a{
    color : $blue;
    text-decoration: underline;
    font-weight: 400;
  }

  p{
    margin-top: 0;
  }

}

ol.flow_list li:after {
  position: absolute;
  top: 0;
  left: 0;
  content: counter(steps);
  counter-increment: steps;
  border: 1px solid #dfdfdf;
  border-radius: 50%;
  color: #4a90e2;
  font-weight: bold;
  display: inline-block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  background: #fff;
}

ol.flow_list li:before {
  position: absolute;
  left: 13px;
  top: 0;
  content: "";
  height: 100%;
  width: 0;
  border-left: 1px solid #dfdfdf;
}

ol.flow_list li:last-of-type:before {
  border: none;
}

ol.flow_list :global(.sm-l-t) {
  color: $grey2;
}

.options {
  :global(.checkbox label){
    @include lg-t-b;
    font-size: 13px;
    line-height: 18px;
    color : black;
    
  }
}

.option_text{
  padding-left: 27px;
  margin-top: 5px;
}
