/*** Ogólne *******************************************************************/

.buttons
{
	text-align: right;
	.button{
		margin-right: 10px;
		&:last-child{
			margin-right:0;
		}
	}
}

th.actions
{
	text-align: center;
	width: 110px;
}

/* Usługi */

.services-add #list-group h2 a
{
	margin: 0 0 5px 0;
	padding: 0;

	font-size: 28px;
	font-weight: normal;
	color: #69b645;
}

/*** Panel logowania **********************************************************/

body#module-users-action-login
{
	background-image: url('/public/images/login-bg-hq.jpg'), url('/public/images/login-bg.jpg');
	background-repeat: no-repeat, no-repeat;
	background-position: center center, center center;
	background-size: cover, cover;
}

/*** Jednostki ****************************************************************/

.units-browse .shortcut
{
	width: 50px;
}

/*** Stawki VAT ***************************************************************/

.vatrates-browse .value
{
	width: 75px;
	text-align: center;
}

.vatrates-browse td.value
{
	text-align: right;
}

.vatrates-browse tr.filters th.value input
{
	width: 55px !important;
	text-align: right;
}

/*** Produkty *****************************************************************/

.products-browse .price
{
	width: 65px;
}

.products-browse td.price
{
	text-align: right;
}

/*** Magazyny *****************************************************************/

/* Lista */

.storages-browse .id
{
	width: 30px;
	text-align: center;
}

.storages-browse .caretaker
{
	width: 125px;
}

/* Dodanie/Edycja */

.storages-add .name,
.storages-add .name
{
	width: 98%;
}

/* Edycja */

.storages-edit #name,
.storages-edit #caretaker
{
	width: 98%;
}

.storages-edit #products-list .index
{
	width: 100px;
}

.storages-edit #products-list .price,
.storages-edit #products-list .quantity,
.storages-edit #products-list .booked,
.storages-edit #products-list .value,
.storages-edit #products-list .vattype,
.storages-edit #products-list .valuebrutto
{
	width: 70px;
	text-align: center;
}

.storages-edit #products-list td.price,
.storages-edit #products-list td.quantity,
.storages-edit #products-list .booked,
.storages-edit #products-list td.value,
.storages-edit #products-list td.vattype,
.storages-edit #products-list td.valuebrutto
{
	text-align: right;
}

/* Przyjęcie */

.storages-admission #contractorId
{
	width: 520px;
}

.storages-admission #products .photo
{
	text-align: center;
}

.storages-admission .overall-value
{
	margin: 5px 0px;
	padding-bottom: 5px;
	
	font-size: 13px;
	font-weight: bold;
	text-align: right;
	
	border-bottom: 1px solid #cacaca;
}

.storages-admission #products td.value,
.storages-admission #products td.valuebrutto
{
	text-align: right;
}
/* Przeniesienie */

.storages-move #storage
{
	float: left;

	margin-right: 1%;
	width: 49%;
}

.storages-move #base
{
	float: left;

	margin-left: 1%;
	width: 49%;
}

.storages-move #storage td,
.storages-move #base td
{
	height: 40px;
}

.storages-move #additionalStorageId
{
	width: 345px;
}

.storages-move #products,
.storages-move #additional
{
	clear: both;
}

/*** Wzorce *******************************************************************/

.packetspatterns-browse .index
{
	width: 100px;
}

.packetspatterns-browse .price
{
	width: 100px;
}

.packetspatterns-browse td.price
{
	text-align: right;
}

/*** Kontrahenci **************************************************************/

.contractors-add #name,
.contractors-edit #name,
.contractors-add #accountNumber,
.contractors-edit #accountNumber
{
	width: 98%;
}

.contractors-add #notes,
.contractors-edit #notes
{
	width: 98%;
	height: 150px;
}

