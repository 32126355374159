.toggle-button {
	float: right;
	display: inline-block;
	margin-top: -5px;
	background: #7CB143;
	color: #fff;
	border-radius: 5px;
	padding: 5px;
	margin-left: 10px;
}

div.waste-group-header[data-visible="N"] span.toggle-button.visible {
	display: none;
}

div.waste-group-header[data-visible="N"] span.toggle-button.hidden {
	display: inline-block;
}

div.waste-group-header[data-visible="Y"] span.toggle-button.visible {
	display: inline-block;
}

div.waste-group-header[data-visible="Y"] span.toggle-button.hidden {
	display: none;
}

div.waste-group-header {
	padding: 10px;

	color: #000;

	cursor: pointer;

	border: 1px solid #bbb;
	border-radius: 10px;

	background: #f5f5f5;
}

div[id*="cg-"] table {
	display: none;
}

div[id*="cg-00"] table {
	display: table;
}

div.info {
	font-style: italic;
	color: #bbb;
}

td.code {
	width: 100px;
	text-align: center;
}

._check {
	width: 50px;
	text-align: center;
}

.bdo-browseall .id
{
	width: 30px;
	text-align: center;
}

.bdo-browseall .created
{
	width: 120px !important;
	text-align: center;
}

.bdo-browseall .userid
{
	width: 50px !important;
	text-align: center;
}

.offices-list
{
	margin: 10px 0;
	width: 100%;

	table-layout: fixed;

	border-collapse: collapse;
	border-top: 1px solid #909090;
	border-left: 1px solid #909090;
}

.offices-list td,
.offices-list th
{
	padding: 4px;
	border-right: 1px solid #909090;
	border-bottom: 1px solid #909090;
}

.bdo-w2 .fields-group[id^="g"]
{
	display: none;
}

#p1-radiolist .box,
#p123-radiolist .box,
#e9-radiolist .box,
#e10-radiolist .box,
#e11-radiolist .box
{
	display: inline-block;
    margin-right: 30px;
    input{
        width:auto;
    }
}

#info-group
{
	margin: 5px 0px;
	padding: 10px;

	border: 2px solid #82B955;
	border-radius: 5px;

	background: #e8f4dc;
}

#info-group h2
{
	border: 0 !important;
}

#info-group h2 a span
{
	cursor: pointer;
}

#info-group h2 a span.link
{
	float: right;

	font-size: 12px;
	text-decoration: underline;
}

#info-message
{
}

#finish-message
{
	text-align: center;
}

#finish-message u
{
	color: #993300;
	font-weight: bold;
}

#finish-message .button
{
	display: inline-block;
	margin: 20px auto;
}

.buttons
{
	float: right;

	&.left{
		float:left;
	}

	&.center{
		float:left;
		width:100%;
		text-align: center;
	}
}

.bdo-message
{
	border: 2px solid #cacaca;
	border-radius: 5px;
	padding: 10px;
	color: #454545;
}

table.list .created
{
	width: 150px;
	text-align: center;
}

table.details .caption
{
	width: 250px;
}

input.text.p3,
input.text.p53,
input.text.p64,
input.text.p75,
input.text.p86,
input.text.p114,
input.text.p115,
input.text.p116,
input.text.p117,
input.text.p118,
input.text.p119,
input.text.p101,
input.text.p111,
input.text.p120,
input.text.p121,
input.text.p122
{
	width: 100%;
}

.p30-row .value,
.p31-row .value,
.p32-row .value,
.p33-row .value,
.p34-row .value,
.p35-row .value
{
	text-align: center;
}
