// @import "~bootstrap/scss/bootstrap";

.mb-30{
    margin-bottom:30px !important;
}
.mb-20{
   
    margin-bottom:20px !important;
}
.mb-5{
    margin-bottom:5px !important;
}
.mb-10{
    margin-bottom:10px !important;
}
.mt-10{
    margin-top:10px !important;
}
.mt-20{
    margin-top:20px !important;
}
.mt-30{
    margin-top:30px !important;
}
.ml-10{
    margin-left:10px !important;
}
.mr-10{
    margin-right:10px !important;
}

.auto-center{
    margin-left: auto;
    margin-right: auto;
}
.text-center{
    text-align: center;
}
.flex{
    display:flex;
    .grow-1{
        flex-grow: 1;
    }
}
@import './base/variables.scss';
@import './base/mixins.scss';
@import './base/typography.scss';
@import './base/default.scss';
@import './base/fonts.scss';


@import './base/modules.scss';
@import './base/layout.scss';
@import './base/page.scss';
@import './base/menu.scss';

@import './base/box.scss';
@import './base/buttons.scss';
@import './base/tables.scss';
@import './base/services.scss';
@import './base/forms.scss';
@import './common/table-form.scss';
@import './base/modal.scss';
