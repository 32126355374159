@import 'src/styles/base/variables.scss';

.waste_card_form{
    :global(.caption){
        width: 250px;
    }
    :global(.details){
        margin-bottom: 30px;;
    }

    table{
        h2{
            border-bottom: 0 !important;
            
        }
    }

    :global(.buttons){
        margin-top: 40px;
    }
}

.actions{
    text-align: center;
}

.no_v_p{
    padding-top:0;
    padding-bottom:0;
}

.table_sub_h{
    font-size: 110%;
    font-weight: bold;
}

.btn_cell{
    :global(.button){
        float:right;
        border-top:0;
    }
}
table.waste_item_list{
    margin-bottom:50px;
}

div.waste_group_header {
	padding: 10px;
    margin-top:10px;
	color: #000;

	cursor: pointer;

	border: 1px solid #bbb;
	border-radius: 10px;

    background: #f5f5f5;
    
    &.hidden{
        .waste_list{
            display:none;
        }
    }
}

.address_field{
    align-items:center;
    display: flex;
    font-weight: bold;
     > div:first-child{
        flex-grow: 1;
     }

     > div:last-child{
         font-family: Calibri;
        width: 200px;
        text-align: center;
        color: $primary-green;
     }
}
.no_margin{
    margin-bottom:0;
}
.extended_header{
    margin-bottom: 20px;
}

.date_info{
    margin-right: 30px;
    display:inline-block;
}

.waste_inputs{
    width: 270px;
    input,select{
        width:100%;
    }
    :global(.half-col){
        padding: 0 10px;
    }
}

.waste_list{
    margin-bottom: 20px;;
}