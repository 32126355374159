.table_form{
    :global(.caption){
        width: 250px;
    }
    :global(.details){
        margin-bottom: 30px;;
    }

    table{
        h2{
            border-bottom: 0 !important;
            
        }
    }

    :global(.buttons){
        margin-top: 40px;
    }
}

.waste-list{
    .amount-field{
        margin-right: 7px;
    }
    .address-row{
        &.hidden{
            display:none;
        }
    }
    th.code{
        border-right: 1px solid #d6d8db;
    }
    td.code{
        border-right: 1px solid #d6d8db;
        // font-weight: 500;
        font-family: Roboto-Regular;
    }
    .address-row{
      
       td.code{
           border-bottom: none;
       }
    }
}

.validation_error{
    color: red;
    margin-top: 5px;
    font-size: 12px;
}


// .waste-address-list{
//     margin-bottom: 20px;
//     box-shadow: 0 0 5px 0.25px #cacaca;
//     background-color: white;
//     border-radius: 7px;
//     border-left :  1px solid #d6d8db;
//     border-right :  1px solid #d6d8db;
//     border-top :  1px solid #d6d8db;

//     .code{
//         width: 100px;
//     }
//     .name{
//         width: 500px;
//     }
//     .action-btn{
//         flex-grow: 1;
//     }
//     .button{
//         height: 25px;
//         line-height: 25px;
//     }
//     .text-right{
//         text-align: right;;
//     }
//     *{
//         box-sizing: border-box;
//     }
//     .code-check{
//         width:45px !important;
        
//     }
//     table.waste-list{
//         margin: 5px 0;
//         margin-top:0;
//         box-shadow: none;
//         border-radius: 7px;
//         border-right: 1px solid #d6d8db;
//         *{
//             // border
//         }
//     }
//     .list-header{
//         display: flex;
//         // margin: 10px 0px;
//         width: 100%;
       
//         // border-top: 1px solid #d6d8db;
//         border-bottom: 1px solid #d6d8db;
//         // border: 1px solid #d6d8db;
//         background-color :#f9f9f9;
//         width:100%;
//         border-top-left-radius: 7px;
//         border-top-right-radius: 7px;
//         > div{
//             padding: 10px;
//             // width: 33.33%;
//             font-size: 14px;
//             font-weight: 500;
//         }
//     }

//     .code-row{
//         border-bottom: 1px solid #d6d8db;
//     }

//     .code-row-header{
//         display:flex;
//         background-color: white;
//         font-weight: 500;
//         align-items: center;
//         height: 60px;
//         > div{
//             // width: 33.33%;
//             // flex-grow: 1;
//             padding: 10px;
//             // 
//             font-size: 14px;
//             font-weight: bold;

//         }
       

//         // border-left :  1px solid #d6d8db;
//         // border-right :  1px solid #d6d8db;
//     }
//     .address-string{
//         width: 30%;
//     }

//     .code-addresses-list{
//         padding: 0px 10px 10px 10px;
//         &.hidden{
//             display:none;
//         }

//     }
// }