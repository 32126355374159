@import 'src/styles/common/table-form.scss';
@import 'src/styles/base/variables.scss';

.header_checkbox{
    margin-left: 20px;
    margin-top: 10px;
}

.header{
    font-family : poppins;
    font-weight: 600 !important;
    font-size: 34px !important;
}

.h_aligned{
    margin-bottom: 38px;
}
.required_note{
    text-align:left;
    margin:0;
    font-weight: bold;
    color: $black;
}
.required{
    font-size: 18px;
}