/*** Przyciski + linki ********************************************************/
input.button,
button.button,
a.button
{
	display: inline-block;
	height: 26px;

	margin: 0;
	padding: 9px 20px 0px 20px;

	cursor: pointer;

	font-size: 14px;
	font-family: "Calibri", "Open Sans", "Tahoma", "Verdana", "Sans-Serif";
	font-weight: bold;
	color: #ffffff;
	white-space: nowrap;

	text-align: center;
	vertical-align: middle;

/*	border: 1px solid #696969; */
	border: 0;
	border-radius: 5px;

	background-color: #7cb143;
}

button.button{
	height: 35px;
	padding-top:0;
	&:disabled{
		background-color: lighten( #7cb143, 25%)
	}
}

.inverted-button
{
	
	
	// border: 1px solid #7CB143 !important;
	background: #ffffff !important;
	color: #7CB143 !important;
	&:disabled{
		border: 1px solid white;
		color: lighten(#7CB143, 40%) !important;
	}

	&.border{
		border: 1px solid darken($light-grey2, 10%);
	}
}

.inverted-button:hover
{
	background: lighten( #e1f4cb, 10%) !important;
}

.disabled-button
{
	border: 1px solid #a9adb2 !important;
	background: #BCC0C5 !important;
}

.disabled-button:hover
{
	background: #cdd2d8 !important;
}

.link-button
{
	display: inline-block;
	margin: 9px 10px;

	font-size: 16px;

	border: 0px solid #7CB143 !important;
	box-shadow: none !important;
	background: none !important;
	color: #3198CD !important;
}

input.button
{
	padding: 7px 10px;
	height: 35px;
}

a.button
{
	text-decoration: none !important;
}

input.button:hover,
a.button:hover
{
	background-color: #9ccc66;
}

a.button-ok,
input.button-submit,
#button-submit
{
	background-color: #7cb143;
}

a.button-ok:hover,
input.button-submit:hover,
#button-submit:hover
{
	background-color: #9ccc66;
}

a.button-cancel
{
}

a.button-cancel:active
{
}

a.button-big
{
	padding: 5px 10px;
}

/*** Przyciski sortowania *****************************************************/

.sort-asc,
.sort-desc,
.sort-asc-sel,
.sort-desc-sel
{
	float: right;

	font-size: 10px;
	_font-size: 7px; /* IE wyświetla strzałki "giganty" :) */
}

.sort-asc a:hover,
.sort-desc a:hover,
.sort-asc-sel a:hover,
.sort-desc-sel a:hover
{
	text-decoration: none;
}
