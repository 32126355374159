.w_glass_panel {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  margin: auto;
}
.window_panel {
  position: absolute;
  z-index: 1005;
  width: 300px;
  height: auto;
  left: 810px;
  top: 400px;
  top: 50%;
  transform: translateY(-50%);

  border: 1px solid #aaaaaa;
    background: #ffffff;
    box-shadow: 0px 0px 15px 2px #cacaca;

    left: 0;
    right: 0;
    margin: auto;
}

.w_header {
  height: 35px;
  overflow: hidden;
  user-select: none;
  width: 300px;
  background-color: #7CB143 !important;

  div{
    font-family: sans-serif;
    font-size: 16px;
    color: #ffffff;
  }

  > div {
    white-space: nowrap;
    overflow: hidden;
    padding: 8px 50px 8px 8px;
    width: 150px;
  }
}

.w_body {
  margin: 0px;
  padding: 0px;
  width: 300px;
  height: auto;
}

.spinner_window_content {
  padding: 10px;
  width: auto;
  height: auto;
  text-align: center;
}

.w_buttons {
  height: 40px;
  display: table-cell;
  text-align: right;
  padding: 0px 10px;
  vertical-align: middle;
  width: 300px;
  background-color: #efefef;
}
