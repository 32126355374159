.toast-content{
  z-index: 999999;
  color: white;
  .MuiSvgIcon-root{
    line-height: 30px;
margin-right: 10px;
/* vertical-align: sub; */
height: 30px;
vertical-align: middle;
position: relative;
bottom:2px;
  }
}
