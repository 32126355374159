.modal {
    table.details {
      background-color: #ffffff;
      border: none;
      box-shadow: none;
      background-color: none;
  
      td.caption{
        background: none;
        border:none;
        width: 100px;
      }
        td{
         border:none;
        }
    }
    .inverted-button{
        border: 1px solid darken($light-grey2, 10%);
      }
      table{
          box-shadow: none !important;
      }
  }
  