.validation_error{
    color: red;
    margin-top: 5px;
    font-size: 12px;
}

input:-internal-autofill-selected{
    background-color: white !important;
}

// .select_placeholder{
//     opacity : 0.5;
// }

// option:disabled{
//     opacity : 0.5 !important;
// }

// select {
//     color: red;
//     option {
//         color: black;
//     }
// }