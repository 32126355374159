.loader-box {
  float: left;

  padding: 6px 6px 6px 30px;

  font-size: 12px;
  color: #565656;

  background: url("/images/loader.gif") no-repeat 5px center;
  background-size: 20px 20px;
}

.success-box {
  float: left;

  padding: 6px 6px 6px 30px;

  font-size: 12px;
  color: #00aa00;

  background: url("/images/notices/success.png") no-repeat 5px center;
  background-size: 20px 20px;

  &.full {
    background-color: #f9fff4;
    border: 1px solid #d8e8c9;
    border-radius: 5px;
    margin-top: 5px;
  }
}

.warning-box {
  float: left;

  padding: 6px 6px 6px 30px;

  font-size: 12px;
  color: #db9200;

  background: url("/images/notices/warning.png") no-repeat 5px center;
  background-size: 20px 20px;
}

.error-box {
  float: left;

  padding: 6px 6px 6px 30px;

  font-size: 12px;
  color: #ca0000;

  background: url("/images/notices/error.png") no-repeat 5px center;
  background-size: 20px 20px;
}

.info-box {
  float: left;

  padding: 6px 6px 6px 30px;

  font-size: 12px;
  color: #0000aa;

  background: url("/images/notices/info.png") no-repeat 5px center;
  background-size: 20px 20px;
}

.success-box.framed {
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.warning-box.framed {
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.loader-box.framed,
.error-box.framed {
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.info-box.framed {
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.success-box.full,
.warning-box.full,
.error-box.full,
.info-box.full,
.loader-box.full {
  float: none;
}
