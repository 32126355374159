.sm-l-t {
  @include sm-l-t;
  
}

.sm-t {
  @include sm-t;
}
.md-t {
  @include md-t;
 
  
}
.md-b-t {
  @include md-b-t;
  font-family : 'Roboto-Regular';
  color: black;
 
}

.lg-t {
  @include lg-t;
  font-family : 'Roboto-Regular';
 
}

.lg-t-b {
  @include lg-t-b;
  font-family : 'Roboto-Regular';
  // color:#000000DE;
 
}

.primary-link{
  color: $blue2;
}

.title{
  color: $dark3;
  margin-bottom: 20px;
}

.green{
  color  : $primary-green !important;
}

.heavy{
  font-weight: bold !important;
  color : $black !important;
}

// .md-l-t {
//     @include md-b-t;
//     color: $dark;
//   }

  .grey {
    color: $grey;
  }

  .dark {
    color: $dark;
  }

  .red{
    color: $red2 !important;
  }

  ul, ol{
    padding-left: 25px;
  }

  ol{
    li{
      margin-bottom: 5px;
    }
  }

  .info-list{
     > li{
      margin-bottom:5px;
    }
  }



