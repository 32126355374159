.form_checkbox {
  margin-right: 10px;
}

.vertical {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.required {
   &:after{
      content: "*";
      position: relative;
      left: 2px;
      color: #69b645;
      font-size: 18px;
      line-height: 0;
      height: 5px;
   }

}

.required.double {
   &:after{
      content: "**";
   
   }

}
