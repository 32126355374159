.panel_default{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 5px 0.25px #cacaca;
    margin-bottom: 25px;
    border-top: 1px solid #d6d8db;
    border-left: 1px solid #d6d8db;
  
    .primary-h{
      margin-bottom: 15px; 
    }
  }
  