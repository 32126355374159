@import 'src/styles/base/variables.scss';

::-webkit-input-placeholder
{
	color: #dadada;
}

:-moz-placeholder
{
	color: #dadada;
	opacity:  1;
}

::-moz-placeholder
{
	color: #dadada;
	opacity:  1;
}
:-ms-input-placeholder
{
	color: #dadada;
}
::-ms-input-placeholder
{
	color: #dadada;
}

input.text, input.password,
select, textarea
{
	padding: 6px;

	font-size: 14px;
	color: $black;

	border: 1px solid #dadada;
	border-radius: 5px;
	box-shadow: 0 0 3px 0.5px #dadada;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

	background-color: #ffffff;
}

select{
	width: 100%;
    height: 32px;
}

textarea
{
	width: 100%;
	min-height: 75px;
	resize: vertical;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input.text[readonly], input.password[readonly],
select[readonly], textarea[readonly],
input.text[disabled], input.password[disabled],
select[disabled], textarea[disabled]
{
	color: #bcc0c5;
	background-color: #f4f5f6;
}

input[type="checkbox"]
{
	position: absolute;
	z-index: -1000;
	left: -10000px;
}

input[type="checkbox"] + label
{
	display: inline-block;

	margin: 0px;
	padding: 0px;

	padding-left: 26px;
	height: 18px;

	font-size: 14px;

	background: url('/images/checkbox.png') no-repeat left bottom;
}

input[type="checkbox"]:checked + label
{
	background: url('/images/checkbox.png') no-repeat left top;
}

input[type="checkbox"]:disabled + label
{
	background: url('/images/checkbox.png') no-repeat left center;
	color : $grey;
}
