.page_header{
    margin-bottom: 20px;
    h1{
        display: inline-block;
        margin: 0 0 5px 0;
        padding: 0;
        font-size: 28px;
        font-weight: normal;
        color: #69b645;
    }
    a, button{
        float:right;
    }

    &.no_margin{
        margin-bottom:0;
    }
}

