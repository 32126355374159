.modal {
  :global(table.details) {
    background-color: #ffffff;
    border: none;
    box-shadow: none;
    background-color: none;

    :global(td.caption) {
      background: none;
      border:none;
      width: 100px;
    }

    :global(td){
        
        border:none;
      }
  }
}
