#menu-panel
{
	height: 70px;

	// font-size: 15px !important;
	// font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	text-align: right;
	// font-size: 14px;
}

#menu-panel .user-box
{
	padding: 10px 20px;

	font-size: 13px;
	font-weight: normal;
	text-transform: none;
	text-decoration: none;
	text-align: left;

	color: #BCC0C5;

	border-bottom: 1px solid #EAECED;
}

#menu-panel .user-box .name
{
	margin-top: 3px;
	
	font-weight: bold;
	font-size: 15px;
	color: #79818C;
}

#menu-panel .user-box .email
{
	font-weight: normal;
	font-size: 15px;
	color: #79818C;
}

#menu-panel .company-box
{
	padding: 10px 20px;

	font-size: 13px;
	font-weight: normal;
	text-transform: none;
	text-decoration: none;
	text-align: left;

	border-bottom: 1px solid #EAECED;
}

#menu-panel .company-box .name
{
	font-weight: bold;
	font-size: 15px;

	color: #79818C;
}

#menu-panel .company-box .address
{
	margin-top: 5px;

	font-size: 13px;
	color: #C2C5D6;
}

#menu-panel .company-box .taxnumber
{
	margin-top: 5px;

	font-size: 13px;
	color: #C2C5D6;
}

#menu-panel a.item-level-1
{
	display: table-cell !important;
	position: relative;

	height: 70px;
	vertical-align: middle;

	margin-bottom: 3px;
	padding: 0 10px !important;

	color: #ffffff;
}

#menu-panel a.item-level-1:hover
{
	margin-bottom: 0px;
	
	text-decoration: none;
	color: #ffffff;

	background: linear-gradient(to top, #7CB143 3px, transparent 4px);
}

#menu-panel a.item-level-2
{
	display: block;

	padding: 10px 20px;

	font-size: 14px !important;
	font-weight: bold;
	text-transform: none;
	text-align: left;

	color: #BCC0C5;

	border-bottom: 1px solid #EAECED;
}

#menu-panel a.item-level-2 img
{
	display: inline-block;

	margin-bottom: -3px;
    filter: grayscale(1) brightness(140%);
}

#menu-panel .current a.item-level-2 img
{
    filter: brightness(120%) hue-rotate(250deg) !important;
}

#menu-panel a.item-level-2:hover img
{
    filter: grayscale(0) brightness(100%);
}

#menu-panel a.item-level-2.last
{
	border-bottom: 0px;
}

#menu-panel a.item-level-2:hover
{
	margin-bottom: 0px;
	
	text-decoration: none;
	color: #3198CD;
}

a.current-parent, a.current, span.current a
{
	color: #69b645 !important;
}

